import React from "react";
import "./Traveling_To_Alibaug.css";

const Traveling_To_Alibaug = () => {
  return (
    <div className="Servies_Continer">
      <div className="Eight_Complogo_service">
        <div className="EightComp_Slider_service">
          <div className="Eight_CompHeading_service">
            <h2>Traveling to Alibaug Made Easy & Affordable</h2>
            <div className="heading_Eight_service">
              <div className="section section-1_eight_service"></div>
              <div className="section section-2_eight_service"></div>
              <div className="section section-3_eight_service"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="Servies_Content">
        <div className="Servies_Content_divOne">
            <div className="image-container" >
              <div className="VideoForDesktop">
                 <iframe width="100%" height="420" src="https://www.youtube.com/embed/DHLnV_Z2jNQ?si=vEDNOq-YcMygmF80" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen    style={{ borderRadius: '35px' }}></iframe>
              </div>
              <div className="VideoForMobile">
                 <iframe width="90%" height="188" src="https://www.youtube.com/embed/DHLnV_Z2jNQ?si=vEDNOq-YcMygmF80" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={{ borderRadius: '35px' }}></iframe>              </div>
              </div>
              {/* frameborder */}
         </div>
        <div className="Servies_Content_divTwo">
          <div className="grid">
            {/* <!-- First Row --> */}
            <div className="grid_item">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/parents.png" alt="Parents" />
              </div>
              <div className="Grid_Four_text">
                <h3>Passengers</h3>
                <p>₹420 onwards</p>
              </div>
            </div>
            <div className="grid_item">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/Bike.png" alt="Bike" />
              </div>
              <div className="Grid_Four_text">
                <div id="MotercycleSpeacialCase">
                  <h3>Motorcycle</h3>
                  {/* <img src="/assets/img/service/Round-Exclamation-greay.png" alt="Round-Exclamation"/> */}
                </div>
                <div>
                  <p>₹210 onwards</p>
                  <p className="Grid_Four_text_span"> *Electric scooters are not permitted</p>
                </div>
              </div>
            </div>
            <div className="grid_item" id="petsgriditem">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/pig.png" alt="Pets" />
              </div>
              <div className="Grid_Four_text">
                <div id="specalPets">
                  <h3>Pets</h3>
                  {/* <img src="/assets/img/service/Round-Exclamation-greay.png" alt="Round-Exclamation"/> */}
                </div>
                <p>₹310 onwards </p>
              </div>
            </div>
            <div className="grid_item">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/car.png" alt="Car" />
              </div>
              <div className="Grid_Four_text">
                <h3>4 Wheelers</h3>
                <p>₹1,020 onwards</p>
              </div>
            </div>
            <div className="grid_item" id="cyclegridItems">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/cycle.png" alt="Cycle" />
              </div>
              <div className="Grid_Four_text">
                <h3>Bicycles</h3>
                <p>₹110 onwards</p>
              </div>
            </div>
            <div className="grid_item" id="busesgridItem">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/bus.png" alt="Bus" />
              </div>
              <div className="Grid_Four_text">
                <h3>Buses</h3>
                <p>₹4,500 onwards</p>
              </div>
            </div>
          </div>
          {/* <div className="gridIrtem_spcialCaseClass">
            <div id="gridIrtem_spcialCase" className="grid_item">
              <div className="Grid_Four_img">
                <img src="/assets/img/service/parents.png" alt="ParentsImage" />
              </div>
              <div className="Grid_Four_text">
                <div id="InfantsSpeacialCase">
                  <h3>Infants</h3>
                  <img src="/assets/img/Widget/DangerCircle.png" alt="" />
                </div>
                <p>Free</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Traveling_To_Alibaug;


