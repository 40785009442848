// import React, { useState } from 'react';
// import "./Explore_slider.css";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const Explore_slider = () => {
//   const images = [
//     "/assets/img/explore-page/mandwa-port-hero-banner-1.jpg",
//     "/assets/img/explore-page/mandwa-port-hero-banner-2.jpg",   
//   ];

//   const [activeSlide, setActiveSlide] = useState(0);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     pauseOnHover: true,
//     afterChange: (current) => setActiveSlide(current),
//     customPaging: (i) => (
//       <div className={`slick-dot ${activeSlide === i ? 'slick-active' : ''}`}>
//         {activeSlide === i ? i + 1 : ""}
//       </div>
//     ),

//     responsive: [
//       {
//         breakpoint: 767,
//         settings: {
//           arrows: false,
//           dots: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div className='explore-comman-horizontal-slider'>
//       <Slider {...settings}>
//         {images.map((image, index) => (
//           <div key={index} className='explore-slider-images'>
//             <img src={image} alt={`Slide ${index + 1}`} />
//           </div>
//         ))}
//       </Slider>
//       {images.length > 1 && (
//         <div className="slider-info">
//           <span>0{activeSlide + 1} / 0{images.length}</span>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Explore_slider;


import React, { useState } from 'react';
import "./Explore_slider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Explore_slider = () => {
  const images = [
    { uri: "/assets/img/explore-page/mandwa-port-hero-banner-1.jpg", alt: "Mandwa Port" },
    { uri: "/assets/img/explore-page/mandwa-port-hero-banner-2.jpg", alt: "M2M Ferries at Mandwa Port" },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const formatNumber = (num) => (num < 10 ? `0${num}` : num);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    afterChange: (current) => setActiveSlide(current),
    customPaging: (i) => (
      <div className={`slick-dot ${activeSlide === i ? 'slick-active' : ''}`}>
        {activeSlide === i ? i + 1 : ""}
      </div>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className='explore-comman-horizontal-slider'>
      <Slider {...settings}>
        {images.map((slide, index) => (
          <div key={index} className='explore-slider-images'>
            <img src={slide.uri} alt={slide.alt} />
          </div>
        ))}
      </Slider>
      {images.length > 1 && (
        <div className="slider-info">
          <span>{formatNumber(activeSlide + 1)} / {formatNumber(images.length)}</span>
        </div>
      )}
    </div>
  );
};

export default Explore_slider;
