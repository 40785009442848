import React from "react";
import "./Experience_Ferries.css";

const Experience_Ferries = () => {
  return (
    <div className="Experince_continer">
      <div className="Experince_heading">
         <div className="Experience_Complogo">
            <div className="Experince_heading_TextDecoration">
              <h1>Welcome To M2M Ferries</h1>
              <div className="Expernice_decortive">
                <div className="section section-1_Exp"></div>
                <div className="section section-2_Exp"></div>
                <div className="section section-3_Exp"></div>
              </div>
            </div>
         </div>
      </div>
      {/* <div className='HomeContent-content-page>
        <p>Travel across the bay the smarter way with M2M Ferries, Maharashtra's first Ro-Pax ferry service! Say goodbye to long, tiring road trips and enjoy a fast, hassle-free journey from Mumbai to Mandwa in under 60 minutes. Whether you're traveling solo or with your vehicle, our ferry service offers a convenient, scenic ride across the bay, with breathtaking views of Mumbai Harbour. With space for up to 780 passengers and 120 vehicles—cars, buses, bikes, and even bicycles—you can travel comfortably with your loved ones, including your furry friends in our special onboard area. Book your tickets easily with ferry booking online and find a ferry near you. Experience the joy of travel made easy with M2M Ferries—your gateway to a quicker, more enjoyable journey. Start your adventure today!</p>
      </div> */}


      <div className="Experince_content">
        <div className="Experince_content_DivOne">
          <div className="image-container-BigImage">
            <img src="/assets/img/Experince/Component.png" alt="M2M Ferries - Travel Year-round" />
            <div className="image-overlay"></div>
            <div className="text">
              <h2>Travel Year-round</h2>
              <p>Maharashtra's 1st Ro-Pax Ferry that’s operational year-round, even during monsoons.</p>
            </div>
          </div>
        </div>
        <div className="Experince_content_DivTwo">
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/Veh.png" alt="M2M Ferries - Vehicle Deck"/>
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h2>Vehicle Deck</h2>
              <p>Our vehicle deck can accommodate over 120 cars, two-wheelers, and even buses.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            {/* <img src="/assets/img/Experince/SkodaRoom.jpeg" alt="M2M Ferries - Lounge Area" /> */}
            <img src="/assets/img/Experince/SkodaRoom-1.jpg" alt="M2M Ferries - Lounge Area" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo" id="LougeArea_Text">
              <h2>Lounge Area</h2>
              <p>Our spacious indoor lounge area is equipped with all the amenities for a comfortable sail.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/caf.png" alt="M2M Ferries - Cafeteria" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h2>Cafeteria</h2>
              <p>Our cafeteria is ready with your favourite bites & refreshments to add more flavour to your sails.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/Pet.png" alt="M2M Ferries - Pet Area" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h2>Pet Area</h2>
              <p>Our exclusive pet zone invites your four-legged best friends to join you on your adventures!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience_Ferries;
