import React, { useRef, useState } from "react";
import "./Testimonials_com.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials_com = () => {
  const testimonials = [
    {
      id: 1,
      // imageSrc: "/assets/img/Testominal/UniSex.png",
      name: "Trevor Saldanha",
      text: "Nice, Large, and Comfortable Ferry from Princess Dock Mumbai to Mandwa Port near Alibaug. Easy to Book Online, Very Professionally Organized, Smooth Sailing and Absolutely On Time too. Accommodates a Large Number of Cars and Passengers and has a separate Pet Area too, so you can take your Pets along as well, which is really nice. Has an Open Deck Area as well as a Large Air Conditioned Lounge with Food Stalls and Tables/Chairs to sit down and eat too. A Great Experience and certainly worth it for those living in South Mumbai.",
    },
    {
      id: 2,
      // imageSrc: "/assets/img/Testominal/UniSex.png",
      name: "Sudershan S",
      text: "It was really a great experience. I traveled along with my daughter, an injured friend and my husband. Everything was perfect. Even the elderly did not face any problem. They had electrically operated special chairs manned by men to go up the steps and so the old people had no problem. It was a huge affair with cars parked in and plenty of space to move around. The restaurant served delectable food. On the whole, it was a wonderful experience.",
    },
    {
      id: 3,
      // imageSrc: "/assets/img/Testominal/UniSex.png",
      name: "Sudesh Tiwari",
      text: "Many RoPax are already available in Maharashtra but this one is different in terms of size, ambience and ammenties.You can board from Bhaucha Dhakka, booking can be done online and over the counter booking is also available. Punctuality is maintained, leaves at the dotted time and takes approx 1 hour to reach Mandwa. The AC restaurant is spacious, nicely done and the spread is decent. Merits at least one visit with accompanying seagull's",
    },
    {
      id: 4,
      // imageSrc: "/assets/img/Testominal/UniSex.png",
      name: "Suzana F",
      text: "Absolutely worth the money! One of our friends recommended booking a ferry ride from Mumbai to Mandwa & it was indeed a unique experience. We had booked an evening slot so we got to witness the beautiful sunset. Good food, comfortable seating, absolutely clean washrooms, scenic views & extremely hospitable crew. Definitely, one trip to put on your list of 'to dos'",
    },
    {
      id: 5,
      // imageSrc: "/assets/img/Testominal/UniSex.png",
      name: "Tejas Parekh",
      text: "Absolutely worth the money! One of our friends recommended booking a ferry ride from Mumbai to Mandwa & it was indeed a unique experience. We had booked an evening slot so we got to witness the beautiful sunset. Good food, comfortable seating, absolutely clean washrooms, scenic views & extremely hospitable crew. Definitely, one trip to put on your list of 'to dos'",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "150px",
    slidesToShow: 1,
    variableWidth: true,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          variableWidth: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          variableWidth: false,
        }
      }
    ]
  };

  const goToNextPagetest = () => {
    if (currentPage < testimonials.length) {
      setCurrentPage(currentPage + 1);
      sliderRef.current.slickNext();
    }
  };

  const goToPrevPagetest = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="Nine_comp_Main_Container">
      <div className="Nine_Complogo">
        <div className="NineComp_Slider">
          <div className="Nine_CompHeading">
            <h2>What Our Passengers Say About Us </h2>
            <div className="heading_Nine">
              <div className="section section-1_Nine"></div>
              <div className="section section-2_Nine"></div>
              <div className="section section-3_Nine"></div>
            </div>
          </div>
        </div>
      </div>
      {/* slider are continue */}
      <div className="slider_container_Nine">
        <Slider {...settings} ref={sliderRef}>
          {testimonials.map((slide) => (
            <div key={slide.id} className="slide_Nine">
              {/* <img src={slide.imageSrc} alt={`Slide ${slide.id}`} /> */}
              <p>{slide.text}</p>
              <h2>{slide.name}</h2>
            </div>
          ))}
        </Slider>
        <div className="slider_nav_Nine">
          <div className={`prevArrow_Nine ${currentPage === 1 ? "disabled" : ""}`} onClick={goToPrevPagetest} disabled={currentPage === 1}>
            <img src="/assets/img/Partners/arrow.png" alt="dcs" />
          </div>
          {/* <p className="PtagToatalPages">{`${currentPage}/${testimonials.length}`}</p> */}
          <p className="PtagToatalPages">{`${String(currentPage).padStart(2, '0')}/${String(testimonials.length).padStart(2, '0')}`}</p>
          <div className={`nextArrow_Nine ${ currentPage === testimonials.length ? "disabled" : "" }`} onClick={goToNextPagetest} disabled={currentPage === testimonials.length}>
            <img src="/assets/img/Partners/arrow.png" alt="dcs" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials_com;
