// new code  with dible logic
import React, { useEffect, useRef, useState } from "react";
import "./Our_Partners.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaticDataService from "../../services/staticData";
import { M2MApiUrl } from "../../services/staticData";

const Our_Partners = () => {
  const [isSlides, setIsSlides] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef(null);

  useEffect(() => {
    StaticDataService.HomePartnersBanners()
      .then((response) => {
        const partnerBanners = processBannerData(response.Banners);
        setIsSlides(partnerBanners);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  const processBannerData = (data) => {
    return data.map((banner) => ({
      uri: `${M2MApiUrl}${banner.image.replace("public/", "storage/")}`,
      mobile_image: `${M2MApiUrl}${banner.mobile_image.replace("public/", "storage/")}`,
      redirectTo: banner.redirect_url,
      is_external: banner.is_external,
      id: banner.id,
      alt:banner.title
    }));
  };
  console.log("akas h: ",isSlides)
  const NextArrow = ({ currentSlide, slideCount }) => {
    return (
      <div className={`partners_arrow nextArrow_Six ${currentSlide === slideCount - 1 ? "disabled" : ""}`}
        onClick={() => {
          if (currentSlide < slideCount - 1) {
            sliderRef.current.slickNext();
          }
        }}>
        <svg className="svg_arrow_clss" xmlns="http://www.w3.org/2000/svg" width="11.924" height="23.617" viewBox="0 0 11.924 23.617">
          <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M19.387,8.309,9.693,0,0,8.309" transform="translate(10.424 2.115) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
        </svg>
      </div>
    );
  };
  
  const PrevArrow = ({ currentSlide }) => {
    return (
      <div className={`partners_arrow prevArrow_Six ${currentSlide === 0 ? "disabled" : ""}`}
        onClick={() => {
          if (currentSlide > 0) {
            sliderRef.current.slickPrev();
          }
        }}>        
        <svg className="svg_arrow_clss" xmlns="http://www.w3.org/2000/svg" width="11.924" height="23.617" viewBox="0 0 11.924 23.617">
          <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M19.387,0,9.693,8.309,0,0" transform="translate(9.809 2.115) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
        </svg>
      </div>
    );
  };
  
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: true, 
    autoplaySpeed: 6000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <div className="Six_comp_Main_Container">
      <div className="Nine_Complogo">
        <div className="NineComp_Slider">
          <div className="Nine_CompHeading">
            <h1></h1>
            <div className="heading_Nine">
              <div className="section section-1_Nine"></div>
              <div className="section section-2_Nine"></div>
              <div className="section section-3_Nine"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-container">
        {isSlides ? (
          <>
          <Slider {...settings} ref={sliderRef}>
            {isSlides.map((slide, index) => (
              <div key={index} className="slide">
                <div className="imageContainer imageContainer_Desktop">
                  {slide.redirectTo === null ? (
                      <img src={slide.uri} alt={slide.alt} />
                  ) : (
                      <a href={slide.redirectTo} target="_blank">
                        <img src={slide.uri} alt={slide.alt} />
                      </a>
                  )}
                </div>
                <div className="imageContainer_Mobile">
                  {slide.redirectTo === null ? (
                        <img src={slide.mobile_image} alt={slide.alt} />
                    ) : (
                        <a href={slide.redirectTo} target="_blank">
                          <img src={slide.mobile_image} alt={slide.alt} />
                        </a>
                    )}
                  
                </div>
              </div>
            ))}
          </Slider>
         <div className="slider_nav">
            <PrevArrow currentSlide={currentSlide} />
            {/* <p className="PtagToatalPages">{`${currentSlide + 1}/${isSlides.length}`}</p> */}
            <p className="PtagToatalPages">{`${String(currentSlide + 1).padStart(2, '0')}/${String(isSlides.length).padStart(2, '0')}`}</p>
            <NextArrow currentSlide={currentSlide} slideCount={isSlides.length} />
          </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Our_Partners;





