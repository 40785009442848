import React from 'react';
import "./Explore_Tabs_Section.css"

const Explore_Tabs_Section = ({ scrollToSection, aboutRef, attractionRef }) => {
  // console.log(scrollToSection, aboutRef, attractionRef )
  return (
    <div className='explore-page-main-section'>
          <div className='explore-info-tabs'>
                <div className='explore-info-tabs-div' onClick={() => scrollToSection(aboutRef)}>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                    <h2>About Mandwa Port</h2>
                </div>
                <div className='explore-info-tabs-div' onClick={() => scrollToSection(attractionRef)}>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                    <h2>Top Attraction</h2>
                </div>
                {/* <div className='explore-info-tabs-div'>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                    <h2>Activities</h2>
                </div>
                <div className='explore-info-tabs-div'>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                    <h2>Best Time To Visit</h2>
                </div>
                <div className='explore-info-tabs-div'>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                    <h2>Book Your Experience</h2>
                </div> */}
          </div>

          <div className='about-mandwa-port-section' ref={aboutRef}>
              <div className='about-heding-mobile'>
                      <h2>About Mandwa Port</h2>
                      <div className="about-mandwa-heading-bottam-line" style={{margin:"auto"}}>
                          <div className="section bottam-line-1"></div>
                          <div className="section bottam-line-2"></div>
                          <div className="section bottam-line-3"></div>
                      </div>
                    </div>
              <div className='about-mandwa-port-section-div-1'>
                <img src="/assets/img/explore-page/about-mandwa-port-1.png" alt="Mandwa-Port"/>
              </div>
              <div className='about-mandwa-port-section-div-2'>
                <div className="about-mandwa-heading">
                    <div className='about-heding-desktop'>
                      <h2>About Mandwa Port</h2>
                      <div className="about-mandwa-heading-bottam-line">
                          <div className="section bottam-line-1"></div>
                          <div className="section bottam-line-2"></div>
                          <div className="section bottam-line-3"></div>
                      </div>
                    </div>
                    <p>
                      The only world class Roll-On-Roll-Off-Passenger (Ro-Pax for short) Ferry service in the country. Our service cuts the usual travel time from 5 hours by road (111km) to just 1 hour by sea (19km) and provides all weather year-round connectivity. M2M Ferries Private Limited (M2M Ferries) connects Mumbai and Mandwa. We operate a modern, efficient and comfortable service between these two key locations in Maharashtra. M2M redefined waterways by with the support of the Maharashtra Maritime Board and Mumbai Port Trust.
                      <br /><br />
                      M2M-1, the Ro-Pax Ferry operates between Ferry Wharf (Bhaucha Dhakka) in Mumbai and Mandwa Jetty in Raigad seven days a week throughout the year. We provide cost-effective and efficient waterway connectivity for
                    </p>
                </div>
              </div>
          </div>
    </div>
  )
}

export default Explore_Tabs_Section